.ellipse {
    width: 1072px;
    height: 600px;
    top: 84px;
    left: 107px;
    background: linear-gradient(180deg, rgba(44, 123, 229, 0.4) 0%, rgba(180, 50, 151, 0.252) 65.84%);
    position: absolute;
    filter: blur(110px);
    z-index: -3;
}

.computer {
    width: 459px;
    height: 379px;
    background: url("../../assets/img/team-7035901_1920.jpg") no-repeat;
    background-size: contain;
}

